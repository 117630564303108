<template>
  <div class="user-training-module-block">
    <div class="head">
      <div class="name">{{ name }}</div>
      <div class="status">
        <training-module-status :module-id="id"></training-module-status>
      </div>
    </div>
    <div class="label">
      {{ label }}
    </div>
    <div class="content" v-if="moduleParts.length">
      <p class="part-count">
        {{ moduleParts.length }} {{ $tc('training.part', moduleParts.length) }}
      </p>
      <div class="parts">
        <div class="part" v-for="(partId) in moduleParts" :key="partId">
          <training-module-part-button :module-id="id" :part-id="partId"></training-module-part-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrainingModuleStatus from '@/components/training/TrainingModuleStatus'
import { mapState } from 'vuex'
import TrainingModulePartButton from '@/components/training/TrainingModulePartButton'

export default {
  name: 'UserTrainingModuleBlock',
  components: { TrainingModulePartButton, TrainingModuleStatus },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    moduleParts: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  computed: {
    ...mapState('training', ['parts'])
  }
}
</script>

<style lang="scss" scoped>
.user-training-module-block {
  border-radius: $radius-xl $radius $radius $radius;
  background-color: #fff;
  .head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .name {
      font-size: $fz-l;
      font-weight: $fw-l;
      background-color: $c-secondary;
      color: #fff;
      padding: $space-m $space-ml;
      border-top-left-radius: $radius;
      border-bottom-right-radius: $radius;
      height: 66px;
    }
    .status {
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $space-s;
    }
  }
  .label {
    padding: $space-m $space-ml;
    font-size: $fz-xl;
    font-weight: $fw-l;
    text-align: left;
  }
  .content {
    .part-count {
      padding: 0 $space-ml;
    }
    .parts {
      margin-top: $space-sm;
      .part {
        border-top: 1px solid $c-border;
        &:last-child {
          .training-module-part-button {
            border-radius: 0 0 $radius $radius;
          }
        }
      }
    }
  }
}
</style>
