<template>
  <div class="training-summary" v-if="module !== undefined">
    <div class="wrapper">
      <user-training-module-block :label="module.label" :name="module.name" :id="module.id" :module-parts="module.parts"></user-training-module-block>
      <app-button-layout color="alternate" @click="redirectToOscar()">
       {{ $t('global.navigation.backToDashboard') }}
      </app-button-layout>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import UserTrainingModuleBlock from '@/modules/user/components/UserTrainingModuleBlock'
import { useStore } from 'vuex'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import redirectToOscar from '@/utils/redirectToOscar'
import VIEWS_NAMES from '@/constants/VIEWS_NAMES'

export default {
  name: 'trainingSummary',
  components: { AppButtonLayout, UserTrainingModuleBlock },
  setup () {
    const route = useRoute()
    const store = useStore()
    const router = useRouter()
    const moduleId = route.params.moduleId
    const module = store.state.training.modules[moduleId]
    if (module === undefined) {
      router.push({ name: VIEWS_NAMES.NOT_FOUND })
    }
    return { module, redirectToOscar }
  }
}
</script>

<style lang="scss" scoped>
.training-summary {
  background-color: $c-text;
  height: 100%;
  .wrapper {
    max-width: $training-max-width-tablet;
    margin: 0 auto;
    padding: $space-l 0 $space-xl 0;
    .app-button-layout {
      margin: $space-m auto;
    }
  }
}
</style>
