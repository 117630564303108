<template>
  <div class="training-module-part-button" @click="goToPart" tabindex="0" :class="{ 'is-locked': isLocked }">
    <div class="label-wrapper">
      <p class="part-index">{{ partIndex + 1 }}</p>
      <p class="label">{{ getPartLabel }}</p>
    </div>
    <div class="icon-wrapper">
      <img v-if="isLocked" class="status-icon lock" :src="require('@/assets/icons/lock.svg')" :alt="$t('global.imageAlts.lockIcon')">
      <img v-else class="status-icon arrow" :src="require('@/assets/icons/right-herringbone.svg')" :alt="$t('global.imageAlts.rightArrow')">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import VIEWS_NAMES from '@/constants/VIEWS_NAMES'
import { STEPS } from '@/data/trainingModules'
import useTrainingUtils from '@/utils/useTrainingUtils'

export default {
  name: 'TrainingModulePartButton',
  props: {
    moduleId: {
      type: String,
      required: true
    },
    partId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { getPartIndex } = useTrainingUtils()
    const partIndex = getPartIndex(props.moduleId, props.partId)
    return { partIndex }
  },
  computed: {
    ...mapState('training', ['modules', 'parts']),
    ...mapGetters('training', ['isPartLocked', 'isPartFinished', 'isPartStarted']),
    isLocked () {
      return this.isPartLocked(this.moduleId, this.partId)
    },
    isStarted () {
      return this.isPartStarted(this.partId)
    },
    isFinished () {
      return this.isPartFinished(this.partId)
    },
    getPartLabel () {
      return this.parts[this.partId].label
    }
  },
  methods: {
    goToPart () {
      if (!this.isLocked) {
        this.$router.push({
          name: VIEWS_NAMES.TRAINING,
          params: {
            modulePath: this.modules[this.moduleId].path,
            partPath: this.parts[this.partId].path,
            stepPath: STEPS.INTRO.PATH
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.training-module-part-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space-m;
  cursor: pointer;
  transition: $color-transition;
  &.is-locked {
    cursor: default;
    .label-wrapper {
      .part-index {
        background-color: $c-locked;
      }
    }
  }
  .label-wrapper {
    display: flex;
    align-items: center;
    .part-index {
      flex-shrink: 0;
      color: #fff;
      width: 20px;
      height: 22px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $space-m;
      background-color: $c-secondary;
      font-size: $fz-s;
      font-weight: $fw-l;
    }
    .label {
      font-weight: $fw-m;
      padding-right: $space-s;
    }
  }
  .icon-wrapper {
    height: 24px;
    padding-right: $space-m;
    .arrow {
      margin-right: 3px;
    }
  }
  &:not(.is-locked):hover {
    background-color: $c-background;
  }
}
</style>
