<template>
  <div class="training-module-status">
    <p v-if="isLocked" class="locked status">
      {{ $t('training.status.locked') }}
    </p>
    <p v-else-if="isFinished" class="done status">
      {{ $t('training.status.finished') }}
      <img :src="require('@/assets/icons/check-circled.svg')" :alt="$t('global.imageAlts.checkIcon')">
    </p>
    <app-button-layout v-else @click="goToModule" :small="mq.tabletMinus">
      {{ isStarted ? $t('training.status.started') : $t('training.status.todo') }}
    </app-button-layout>
  </div>
</template>

<script>
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import VIEWS_NAMES from '@/constants/VIEWS_NAMES'
import { mapGetters, mapState } from 'vuex'
import { STEPS } from '@/data/trainingModules'
import { inject } from 'vue'

export default {
  name: 'TrainingModuleStatus',
  components: { AppButtonLayout },
  props: {
    moduleId: {
      type: String,
      required: true
    }
  },
  setup () {
    const mq = inject('mq')
    return { mq }
  },
  computed: {
    ...mapState('training', ['modules', 'parts', 'steps']),
    ...mapGetters('training', ['isModuleFinished', 'isModuleStarted', 'isModuleLocked', 'isPartStarted', 'isPartFinished', 'isPartTodo', 'isTrainingFinished', 'isFinalTestSuccess']),
    isFinalTest () {
      return this.moduleId === 'final-test'
    },
    isLocked () {
      return this.isFinalTest
        ? !this.isTrainingFinished
        : this.isModuleLocked(this.moduleId)
    },
    isFinished () {
      return this.isFinalTest
        ? this.isFinalTestSuccess
        : this.isModuleFinished(this.moduleId)
    },
    isStarted () {
      return this.isFinalTest
        ? false
        : this.isModuleStarted(this.moduleId)
    }
  },
  methods: {
    goToModule () {
      if (this.isFinalTest) {
        this.$router.push({ name: VIEWS_NAMES.FINAL_TEST })
        return
      }
      let partPath
      if (this.isStarted) {
        const firstNotFinishedPartId = this.modules[this.moduleId].parts.find(partId => (this.isPartStarted(partId) && !this.isPartFinished(partId)) || this.isPartTodo(this.moduleId, partId))
        partPath = this.parts[firstNotFinishedPartId].path
      } else {
        partPath = this.parts[this.modules[this.moduleId].parts[0]].path
      }
      this.$router.push({
        name: VIEWS_NAMES.TRAINING,
        params: {
          modulePath: this.modules[this.moduleId].path,
          partPath: partPath,
          stepPath: STEPS.INTRO.PATH
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.training-module-status {
  .app-button-layout {
    min-height: auto;
  }
  .status {
    font-size: $fz-ml;
    font-weight: $fw-m;
    text-transform: uppercase;
    padding-right: $space-s;
    &.done {
      color: $c-primary;
      display: flex;
      align-items: center;
      img {
        margin-left: $space-m;
      }
    }
    &.locked {
      color: $c-locked;
    }
  }
}
</style>
